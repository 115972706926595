<template>
  <div class="home">
    <div class="description">Each of the instances below is a different benchmarking scenario, with its own characteristics and set of evaluated tools.<br/>If a tool appears in one instance but is absent from other ones, it is likely that it did not perform well enough or was not suitable.<br/></div>
    <div id="display_archived_instances" @click="toggle_archive()">{{this.display_archive}} archived instances</div>
    <InstanceSummary v-for="(content,instance) in structure" :instance="instance" :structure="structure" v-bind:comparisonState="comparisonState" v-bind:is_checked="is_checked" v-bind:comparison_instance="comparison_instance" v-bind:display_archive="display_archive" class="instance_summary"/>
    <div class="spacer"></div>
  </div>
  <Footer/>
</template>

<script>
// @ is an alias to /src
import InstanceSummary from '@/components/InstanceSummary.vue'
import Footer from '@/components/Footer.vue'
import contentStructure from "@/../public/data/structure.json";

export default {
  name: 'Home',
  components: {
    InstanceSummary,
    Footer
  },
  data () {
      return { 
        structure: contentStructure,
        comparisonState: {},
        is_checked: {},
        comparison_instance: ['none'],
        display_archive: "Display"
      }
  },
  mounted() {
      for (var instance in this.structure){
        this.comparisonState[instance] = 'no_comparison';
        this.is_checked[instance] = false;
      }
  },
  methods: {
    toggle_archive() {
      if (this.display_archive == 'Display'){
        this.display_archive = 'Hide';
      }
      else {
        this.display_archive = 'Display';
      }
    }
  }
}

</script>

<style scoped>
.description {
  text-align: left;
  background-color:#F8FBFD;
  border:0;
  border-radius: 1em;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  width:auto;
  padding:1em;
  border-radius: 0.5em;
  margin:0;
  margin-top:0.5em;
}
.spacer {
  clear:both;
  height:2.2em;
}
#display_archived_instances {
  background-color: white;
  color: black;
  width: 15em;
  border-radius: 1em;
  padding: 0.3em 0;
  margin-bottom: 1em;
  cursor: pointer;
  font-size: 0.8em;
  text-decoration: underline;
}
</style>
